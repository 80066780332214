var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaterials" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: {
                          filterable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierIdList,
                          callback: function($$v) {
                            _vm.supplierIdList = $$v
                          },
                          expression: "supplierIdList"
                        }
                      },
                      _vm._l(_vm.supplierNameList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        disabled: this.isLoadingExport
                      },
                      on: { click: _vm.exportHandle }
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo inline_block mr10",
                      attrs: {
                        headers: _vm.myHeaders,
                        action: this.uploadurl,
                        accept: ".xlsx",
                        "before-upload": _vm.beforeupload,
                        "show-file-list": _vm.showfilelist,
                        data: {
                          applicantName: this.$store.state.realName,
                          isOrderGoods: "0"
                        },
                        "on-success": _vm.UploadSuccess
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: this.isLoadingImport
                          },
                          on: { click: _vm.importHandle }
                        },
                        [_vm._v("导入")]
                      )
                    ],
                    1
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.fabricList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        cloneHandle: _vm.cloneHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _vm.actionsType == "check"
              ? _c(
                  "div",
                  [
                    _c("fabricOrderMaterialsCheck", {
                      attrs: {
                        list: _vm.ruleForm,
                        uploadFileList: _vm.uploadFileList
                      },
                      on: { cancelHandle: _vm.resetForm }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "base_wrap clearfix finished" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("物料基本信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料编号", prop: "materialCode" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { disabled: this.actionsType == "edit" },
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatMeterialClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupMaterialCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.materialCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "materialCode", $$v)
                                  },
                                  expression: "ruleForm.materialCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "系统编号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.materialId))
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "供应商编号", prop: "supplierId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select_single w200 mr10",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectSupplierclick(
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.supplierId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "supplierId", $$v)
                                    },
                                    expression: "ruleForm.supplierId"
                                  }
                                },
                                _vm._l(_vm.supplierNameList, function(item) {
                                  return _c("el-option", {
                                    key: item.supplierId,
                                    attrs: {
                                      label: item.supplierCode,
                                      value: item.supplierId
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "供应商名称",
                                prop: "supplierName"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis w200",
                                  attrs: { title: _vm.ruleForm.supplierName }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ruleForm.supplierName
                                        ? _vm.ruleForm.supplierName
                                        : ""
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "技术规格描述" } },
                            [
                              _c("el-input", {
                                attrs: { title: _vm.ruleForm.combination },
                                model: {
                                  value: _vm.ruleForm.combination,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "combination",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.combination"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "系列" } },
                            [
                              _c("el-input", {
                                attrs: { title: _vm.ruleForm.materialSeries },
                                model: {
                                  value: _vm.ruleForm.materialSeries,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialSeries",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialSeries"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料成分" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.materialComposition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialComposition",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialComposition"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "延米克重" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterOne,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterOne",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterOne"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterTwo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterTwo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterTwo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v("\n                克\n              "),
                              _c("span", { staticClass: "red note" }, [
                                _vm._v(
                                  "注:区间值填写两个值,固定值任意填写一个文本框即可"
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "幅宽" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthStart,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthStart",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthStart"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthEnd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthEnd",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "纱支", prop: "yarnCount" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.yarnCount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "yarnCount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.yarnCount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          on: {
                            click: function($event) {
                              return _vm.resetForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            loading: this.loadingcommit
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ])
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogpopupVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogpopupVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.materialCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "materialCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "table_list",
                  attrs: { data: _vm.popoverslist, border: "", fit: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "系统名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.systemName) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "物料编号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.materialCode) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单据名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.tableNameCh) +
                                " \n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "记录数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.recordNum) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.cancelpopoverHandle()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.savepopoverHandle()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条面料物料主数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary", loading: _vm.removeloading },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }