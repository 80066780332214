var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix finished" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.materialId ? _vm.ruleForm.materialId : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商编号" } }, [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.typeHandleSupplier(
                        _vm.ruleForm.supplierId,
                        this.supplierNameList
                      )
                    ) +
                    "\n          "
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.supplierName ? _vm.ruleForm.supplierName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "技术规格描述" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.combination ? _vm.ruleForm.combination : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系列" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialSeries
                      ? _vm.ruleForm.materialSeries
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料成分" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialComposition
                      ? _vm.ruleForm.materialComposition
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "延米克重" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.weightPerMeterShow
                      ? _vm.ruleForm.weightPerMeterShow
                      : ""
                  )
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "幅宽" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.widthShow ? _vm.ruleForm.widthShow : "")
                )
              ]),
              _vm._v("  \n        ")
            ]),
            _c("el-form-item", { attrs: { label: "纱支" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.yarnCount ? _vm.ruleForm.yarnCount : "")
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }